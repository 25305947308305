.footer-container {
  background-color: #000;
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-weight: 700;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.mail-list-btn {
  font-size: 18px;
  padding: 8px 24px;
  border: 2px solid #380065;
  background-color: #a54ee1;
  color: #fff;
  transform-style: preserve-3d;
  transition: ease-in-out 2s;
  border-radius: 60px;
}

.mail-list-btn:hover {
  cursor: pointer;
  /* background-color: #fff;
  color: #a54ee1; */
  transform: rotateX(360deg);
}

.mail-list-btn:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 6px;
  right: 6px;
  bottom: 4px;
  border: 2px solid #fff;
  transform-style: preserve-3d;
  transform: perspective(100px) translateZ(20px);
  border-radius: 60px;
}

.footer-input {
  padding: 8px 24px;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 2px solid #380065;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items .link {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}


.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-icon-link:hover {
  color: #0c75f0;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

/* .footer-logo {
  height: 120px;
  width: 110px;
} */

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
